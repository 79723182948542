import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import "../css/Estilos.css";
import Modal from "react-modal";
import AOS from "aos";
import "aos/dist/aos.css";
import Moment from "moment";
import { InputAdornment } from "@mui/material";
import IconoBuscar from "../Components/IconoBuscar";
import moment from "moment";

const baseUrl = `${process.env.REACT_APP_BASE_URL}/People1`;

function App() {
  const Parseo = (dato) => {
    if (dato === true) {
      dato = "Si";
    } else {
      dato = "No";
    }
    return dato;
  };

  const [data, setData] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalBorrar, setModalBorrar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [page, setPage] = useState(1);
  const [contador, setContador] = useState(0);
  const [errors, setErrors] = useState({});
  const [personaBorrada, setPersonaBorrada] = useState(false);
  const [busqueda, setBusqueda] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [personaSeleccionada, setPersonaSeleccionada] = useState({
    id: "",
    nombreYApellido: "",
    perfilLinked: "",
    github: "",
    fechaUltimaActualizacion: Date,
    contactar: false,
    seEnvioContac: false,
    contesto: false,
    info: "",
    entrevistar: false,
    entrevistado: false,
    resultadoEntrevista: "",
    enviarOf: false,
    aceptaOf: false,
    ubicacion: "",
    comentarios: "",
  });

  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexComments = /^.{1,255}$/;

  const filtro = (e) => {
    const { busqueda, value } = e.target;
    setBusqueda(value);
    setPage(1);
    console.log(busqueda);
  };

  const changePage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };
  const changePageBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const validationForm = (personaSeleccionada) => {
    let fechaValidate = new Date(personaSeleccionada.fechaUltimaActualizacion);
    let resultado = fechaValidate.getFullYear();
    var today = new Date();
    var year = today.getUTCFullYear();

    let errors = {};
    if (!personaSeleccionada.nombreYApellido.trim()) {
      errors.name = "El campo 'Nombre y apellido' es requerido";
    } else if (!regexName.test(personaSeleccionada.nombreYApellido.trim())) {
      errors.name = "Debe ingresar Caracteres validos.";
    }
    if (!personaSeleccionada.perfilLinked.trim()) {
      errors.perfilLinkedin = "El campo 'Perfil linked in' es requerido";
    }
    if (resultado < 1950 || resultado > year) {
      errors.fecha = "Ingresa una fecha valida.";
    }

    if (!personaSeleccionada.github.trim()) {
      errors.perfilgit = "El campo 'Perfil github' es requerido";
    }
    if (!personaSeleccionada.info.trim()) {
      errors.informacion = "El campo 'Informacion' es requerido";
    }
    if (!personaSeleccionada.resultadoEntrevista.trim()) {
      errors.resultadoDeLaEntrevista =
        "El campo 'Resultado Entrevista' es requerido";
    }
    if (!personaSeleccionada.ubicacion.trim()) {
      errors.ubi = "El campo 'Ubicacion' es requerido";
    }
    if (!personaSeleccionada.comentarios.trim()) {
      errors.comments = "El campo 'Comentarios' es requerido";
    } else if (!regexComments.test(personaSeleccionada.comentarios.trim())) {
      errors.comments = "Los comentarios no deben exceder los 255 caracteres";
    }
    return errors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonaSeleccionada({
      ...personaSeleccionada,
      [name]: value,
    });
    console.log(personaSeleccionada);
  };

  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validationForm(personaSeleccionada));
  };

  const handleChangeCheckbox = (e) => {
    const { name } = e.target;
    setPersonaSeleccionada((prevState) => {
      const newState = {
        ...personaSeleccionada,
        [name]: !prevState[name],
      };
      return newState;
    });
  };
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };
  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };
  const abrirCerrarModalBorrar = () => {
    setModalBorrar(!modalBorrar);
    setPersonaBorrada(!personaBorrada);
  };

  const getPeticion = async () => {
    // Header:
    // - Authorization: Bearer <token>
    await axios
      .get(baseUrl + "/?page=" + page + "&searchString=" + busqueda, {
        headers: { authorization: `Bearer ${sessionStorage.getItem("token")}` },
      })
      .then((response) => {
        setTotalPages(response.data.pages);
        setData(response.data.records);
        setContador(response.data.contador);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const PostPrueba = async () => {
    delete personaSeleccionada.id;
    await axios
      .post(baseUrl, personaSeleccionada, {
        headers: { authorization: `Bearer ${sessionStorage.getItem("token")}` },
      })
      .then((response) => {
  
        setData(data.concat(response.data));
        abrirCerrarModalInsertar();
        // personaSeleccionada.fechaUltimaActualizacion = Moment().format('LLLL');
        console.log(personaSeleccionada);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const putPeticion = async () => {
    await axios
      .put(baseUrl + "/" + personaSeleccionada.id, personaSeleccionada, {
        headers: { authorization: `Bearer ${sessionStorage.getItem("token")}` },
      })
      .then((response) => {
        var respuesta = response.data;
        var dataAux = data;
        dataAux.map((people) => {
          if (people.id === personaSeleccionada.id) {
            people.nombreYApellido = respuesta.nombreYApellido;
            people.perfilLinked = respuesta.perfilLinked;
            people.github = respuesta.github;
            people.fechaUltimaActualizacion =
              respuesta.fechaUltimaActualizacion;
            people.contactar = respuesta.contactar;
            people.seEnvioContac = respuesta.seEnvioContac;
            people.contesto = respuesta.contesto;
            people.info = respuesta.info;
            people.entrevistar = respuesta.entrevistar;
            people.entrevistado = respuesta.entrevistado;
            people.resultadoEntrevista = respuesta.resultadoEntrevista;
            people.enviarOf = respuesta.enviarOf;
            people.aceptaOf = respuesta.aceptaOf;
            people.ubicacion = respuesta.ubicacion;
            people.comentarios = respuesta.comentarios;
          }
        });
        abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const peticionDelete = async () => {
    await axios
      .delete(baseUrl + "/" + personaSeleccionada.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPersonaBorrada();
        abrirCerrarModalBorrar();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const seleccionarPersona = (persona, caso) => {
    setPersonaSeleccionada(persona);
    if (caso === "Editar") return abrirCerrarModalEditar();
    abrirCerrarModalBorrar();
  };

  useEffect(() => {
    AOS.init({ duration: 850 });
    getPeticion();
  }, [page, busqueda, personaBorrada, modalInsertar]);

  const modalStyles = {
    overlay: {
      backgroundColor: 0.2,
      maxWidth: "700px",
      width: "100%",
      margin: "auto",
      padding: 0,
    },
  };
  const modalStylesBorrar = {
    overlay: {
      backgroundColor: 0.2,
      inset: "32.4%",
    },
  };
  const ExportarExcel = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/People1/ExportToExcel?peoples=${busqueda}`,
        {
          headers: {
            authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = `Personas-${moment().format("YYYYMMDD-HHmmss")}.xlsx`;
        document.body.appendChild(link);
        link.click();
      });
    // let navigate = useNavigate();
    
    // if (sessionStorage.getItem("token") !== null) {
    //   // navigate(
    //   //   `${process.env.REACT_APP_BASE_URL}/People1/ExportToExcel?peoples=${busqueda}`
    //   // );

    // }
  };

  return (
    <div className="Global">
      <div className="App">
        <div align="rigth">
          <button
            className="btn btn-secondary"
            onClick={() => {
              ExportarExcel();
            }} //={`${process.env.REACT_APP_BASE_URL}/People1/ExportToExcel?peoples=${busqueda}`}
          >
            Exportar a excel
          </button>
          <br />
        </div>
        <br />
        <button
          onClick={() => {
            abrirCerrarModalInsertar();
          }}
          className="btn btn-success"
        >
          Agregar persona
        </button>
        <br />
        <br />

        <TextField
          placeholder="Search…"
          variant="outlined"
          input
          type="text"
          name="busqueda"
          onChange={filtro}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconoBuscar />
              </InputAdornment>
            ),
          }}
        />

        <br />
        <br />

        <div className="rounded-circle">
          <table className="table table-hover table-striped  App-Table">
            <thead>
              <tr>
                <th>id</th>
                <th>Nombre Y Apellido</th>
                <th>Perfil LinkedIn</th>
                <th>GitHub</th>
                <th>Fecha Ultima Actualizacion</th>
                <th>Contactar</th>
                <th>Se envio contacto</th>
                <th>Contesto</th>
                <th>Info</th>
                <th>Entrevistar</th>
                <th>Entrevistado</th>
                <th>Resultado Entrevista</th>
                <th>Enviar Oferta</th>
                <th>Acepta Oferta</th>
                <th>Ubicacion</th>
                <th>Comentarios</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {data.map((people) => (
                <tr key={people.id}>
                  <td>{people.id}</td>
                  <td>{people.nombreYApellido}</td>
                  <td>{people.perfilLinked}</td>
                  <td>{people.github}</td>
                  <td>
                    {
                      (people.fechaUltimaActualizacion = Moment(
                        people.fechaUltimaActualizacion
                      ).format("YYYY-MM-DD"))
                    }
                  </td>
                  {/* {console.log(people.fechaUltimaActualizacion)} */}
                  <td>{String(Parseo(people.contactar))}</td>
                  <td>{String(Parseo(people.seEnvioContac))}</td>
                  <td>{String(Parseo(people.contesto))}</td>
                  <td>{people.info}</td>
                  <td>{String(Parseo(people.entrevistar))}</td>
                  {/* {console.log(people.entrevistar)} */}
                  <td>{String(Parseo(people.entrevistado))}</td>
                  <td>{people.resultadoEntrevista}</td>
                  <td>{String(Parseo(people.enviarOf))}</td>
                  <td>{String(Parseo(people.aceptaOf))}</td>
                  <td>{people.ubicacion}</td>

                  <td>{people.comentarios}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        seleccionarPersona(people, "Editar");
                      }}
                    >
                      <i className="glyphicon glyphicon-pencil"></i>
                    </button>{" "}
                    {"  "}
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        seleccionarPersona(people, "Borrar");
                      }}
                    >
                      <i className="glyphicon glyphicon-trash"></i>
                    </button>{" "}
                    {"  "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button
          className="btn btn-secondary me-md-4"
          onClick={() => changePageBack()}
        >
          Anterior
        </button>
        <button className="btn btn-secondary" onClick={() => changePage()}>
          Siguiente
        </button>
        <div>
          <p>Resultados: {contador}</p>
        </div>

        <Modal
          isOpen={modalInsertar}
          fade={false}
          closeTimeoutMS={500}
          size="lg"
          style={modalStyles}
        >
          <div data-aos="zoom-out">
            <ModalHeader>Insertar Persona de la base de datos</ModalHeader>

            <ModalBody>
              <div className="form-group">
                <label>Nombre y Apellido: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="nombreYApellido"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                />
                {errors.name && <p className="textErrors">{errors.name}</p>}
                <label>Perfil Linked: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="perfilLinked"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                />
                {errors.perfilLinkedin && (
                  <p className="textErrors">{errors.perfilLinkedin}</p>
                )}
                <label>GitHub: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="github"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {errors.perfilgit && (
                  <p className="textErrors">{errors.perfilgit}</p>
                )}
                <label>Fecha Ultima Actualizacion </label>
                <br />
                <input
                  type="date"
                  className="form-control"
                  name="fechaUltimaActualizacion"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {errors.fecha && <p className="textErrors">{errors.fecha}</p>}

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="contactar"
                    value={personaSeleccionada.contactar}
                    onChange={handleChangeCheckbox}
                  />

                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Contactar
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="seEnvioContac"
                    value={personaSeleccionada.seEnvioContac}
                    onChange={handleChangeCheckbox}
                  />

                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Se envió contacto
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="contesto"
                    onChange={handleChangeCheckbox}
                  />

                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Contesto
                  </label>
                </div>
                <label>Información: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="info"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {errors.informacion && (
                  <p className="textErrors">{errors.informacion}</p>
                )}

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="entrevistar"
                    value={personaSeleccionada.entrevistar}
                    onChange={handleChangeCheckbox}
                  />

                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Entrevistar
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="entrevistado"
                    value={personaSeleccionada.entrevistado}
                    onChange={handleChangeCheckbox}
                  />

                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Entrevistado
                  </label>
                </div>
                <label>Resultado Entrevista: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="resultadoEntrevista"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {errors.resultadoDeLaEntrevista && (
                  <p className="textErrors">{errors.resultadoDeLaEntrevista}</p>
                )}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="enviarOf"
                    value={personaSeleccionada.enviarOf}
                    onChange={handleChangeCheckbox}
                  />

                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Enviar oferta
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="aceptaOf"
                    value={personaSeleccionada.aceptaOf}
                    onChange={handleChangeCheckbox}
                  />

                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Acepta oferta
                  </label>
                </div>
                <label>Ubicacion: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="ubicacion"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {errors.ubi && <p className="textErrors">{errors.ubi}</p>}
                <label>Comentarios: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="comentarios"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {errors.comments && (
                  <p className="textErrors">{errors.comments}</p>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <button className="btn btn-primary" onClick={() => PostPrueba()}>
                Insertar
              </button>{" "}
              <button
                className="btn btn-danger"
                onClick={() => abrirCerrarModalInsertar()}
              >
                Cancelar
              </button>
            </ModalFooter>
          </div>
        </Modal>

        <Modal
          isOpen={modalEditar}
          fade={false}
          closeTimeoutMS={500}
          size="lg"
          style={modalStyles}
        >
          <div data-aos="zoom-out">
            <ModalHeader className="">
              Editar Persona de la base de datos
            </ModalHeader>
            <ModalBody>
              <div className="form-group">
                <label>ID: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={personaSeleccionada && personaSeleccionada.id}
                />
                <label>Nombre y Apellido: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="nombreYApellido"
                  onChange={handleChange}
                  value={
                    personaSeleccionada && personaSeleccionada.nombreYApellido
                  }
                />
                <label>perfilLinked: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="perfilLinked"
                  onChange={handleChange}
                  value={
                    personaSeleccionada && personaSeleccionada.perfilLinked
                  }
                />
                <label> GitHub </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="github"
                  onChange={handleChange}
                  value={personaSeleccionada && personaSeleccionada.github}
                />
                <label>Fecha Ultima Actualizacion </label>
                <br />
                <input
                  type="date"
                  className="form-control"
                  name="fechaUltimaActualizacion"
                  onChange={handleChange}
                  value={
                    personaSeleccionada &&
                    personaSeleccionada.fechaUltimaActualizacion
                  }
                />

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="contactar"
                    value={personaSeleccionada && personaSeleccionada.contactar}
                    onChange={handleChangeCheckbox}
                    checked={personaSeleccionada.contactar}
                  />
                  {/* <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"> */}
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Contactar
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="seEnvioContac"
                    value={
                      personaSeleccionada && personaSeleccionada.seEnvioContac
                    }
                    onChange={handleChangeCheckbox}
                    checked={personaSeleccionada.seEnvioContac}
                  />
                  {/* <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"> */}
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Se envió contacto
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="contesto"
                    value={personaSeleccionada && personaSeleccionada.contesto}
                    onChange={handleChangeCheckbox}
                    checked={personaSeleccionada.contesto}
                  />
                  {/* <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"> */}
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Contesto
                  </label>
                </div>
                <label>Información: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="info"
                  onChange={handleChange}
                  value={personaSeleccionada && personaSeleccionada.info}
                />

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="entrevistar"
                    value={
                      personaSeleccionada && personaSeleccionada.entrevistar
                    }
                    onChange={handleChangeCheckbox}
                    checked={personaSeleccionada.entrevistar}
                  />
                  {/* <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"> */}
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Entrevistar
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="entrevistado"
                    value={
                      personaSeleccionada && personaSeleccionada.entrevistado
                    }
                    onChange={handleChangeCheckbox}
                    checked={personaSeleccionada.entrevistado}
                  />
                  {/* <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"> */}
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Entrevistado
                  </label>
                </div>
                <label>Resultado Entrevista: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="resultadoEntrevista"
                  onChange={handleChange}
                  value={
                    personaSeleccionada &&
                    personaSeleccionada.resultadoEntrevista
                  }
                />

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="enviarOf"
                    value={personaSeleccionada && personaSeleccionada.enviarOf}
                    onChange={handleChangeCheckbox}
                    checked={personaSeleccionada.enviarOf}
                  />
                  {/* <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"> */}
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Enviar oferta
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="defaultCheck1"
                    type="checkbox"
                    name="aceptaOf"
                    value={personaSeleccionada && personaSeleccionada.aceptaOf}
                    onChange={handleChangeCheckbox}
                    checked={personaSeleccionada.aceptaOf}
                  />
                  {/* <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"> */}
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Acepta oferta
                  </label>
                </div>
                <label>Ubicacion: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="ubicacion"
                  onChange={handleChange}
                  value={personaSeleccionada && personaSeleccionada.ubicacion}
                />
                <label>Comentarios: </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="comentarios"
                  onChange={handleChange}
                  value={personaSeleccionada && personaSeleccionada.comentarios}
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <button
                className="btn btn-primary"
                onClick={() => {
                  putPeticion();
                }}
              >
                Insertar
              </button>{" "}
              <button
                className="btn btn-danger"
                onClick={() => abrirCerrarModalEditar()}
              >
                Cancelar
              </button>
            </ModalFooter>
          </div>
        </Modal>

        <Modal
          isOpen={modalBorrar}
          fade={false}
          closeTimeoutMS={500}
          style={modalStylesBorrar}
        >
          <ModalBody>
            ¿Esta seguro que desea eliminar la persona{" "}
            {personaSeleccionada && personaSeleccionada.nombreYApellido}?
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              onClick={() => {
                peticionDelete();
                abrirCerrarModalBorrar();
              }}
            >
              Si
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                abrirCerrarModalBorrar();
              }}
            >
              No
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default App;
