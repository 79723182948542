import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Postulantes from "../pages/Postulantes";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Menu from "../pages/menu";
import { ProfileProvider } from "../hooks/useData";
import { ProfileProviderFalse } from "../hooks/useDataFalse";


function App() {
  return (
    <ProfileProviderFalse>
      <ProfileProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/menu" element={<Menu><Home /></Menu>} />
            <Route exact path="/postulantes" element={<Menu><Postulantes /></Menu>} />
          </Routes>
        </BrowserRouter>
      </ProfileProvider>
    </ProfileProviderFalse>
  );
}
export default App;
