import axios from "axios";
import { createContext, useContext, useState } from "react";

const ProfileContextFalse = createContext(null);

export const ProfileProviderFalse = ({ children }) => {
  const [userDataFalse, setDataFalse] = useState(null);

  const getDataFalse = async () => {
    try {
      let result = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/People1`, {
          headers: {
            authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          return response.data.personas_contactarFalse;
        });
      if (result.data !== null) {
        setDataFalse(result.data);
      }
      setDataFalse(result);
    } catch (error) {
      throw error;
    }
  };

  return (
    <ProfileContextFalse.Provider value={{ userDataFalse, getDataFalse }}>
      {children}
    </ProfileContextFalse.Provider>
  );
};

// Finally creating the custom hook
export const useDataFalse = () => useContext(ProfileContextFalse);
