import axios from "axios";
import { createContext, useContext, useState } from "react";


const ProfileContext = createContext(null);

export const ProfileProvider = ({ children }) => {
  const [userData, setData] = useState(null);

  const getData = async () => {
    try {
      let result = await axios.get(`${process.env.REACT_APP_BASE_URL}/People1`, {headers:{authorization:`Bearer ${sessionStorage.getItem('token')}`}})
        .then((response)=>{
          return response.data.personas_contactarTrue;
        });
      if (result.data !== null) {

        setData(result.data);
      }
      setData(result);
    } 
    catch (error) 
    {
      throw error;
    }
    
  };

  return (
    <ProfileContext.Provider value={{ userData, getData }}>
      {children}
    </ProfileContext.Provider>
  );
};

// Finally creating the custom hook
export const useData = () => useContext(ProfileContext);
