import React, { useState } from "react";
// import md5 from "md5";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import "../css/Login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { useHistory } from "react-router-dom";
const url = `${process.env.REACT_APP_BASE_URL}/Accounts/Login`;
function Login(props) {
  const cookies = new Cookies();
  // const [errors, setErrors] = useState({});
  const [user, setUser] = useState({
    userName: "",
    password: "",
  });
  // let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  // const validationForm = (user) => {
  //   if (!user.userName.trim()) {
  //     errors.name = "El campo 'Nombre y apellido' es requerido";
  //   } else if (!regexName.test(user.userName.trim())) {
  //     errors.name = "Debe ingresar Caracteres validos.";
  //   }
  //   if (!user.password.trim()) {
  //     errors.name = "El campo 'Nombre y apellido' es requerido";
  //   } else if (!regexName.test(user.password.trim())) {
  //     errors.name = "Debe ingresar Caracteres validos.";
  //   }
  // };
  // const handleBlur = (e) => {
  //   handleChange(e);
  //   setErrors(validationForm(user));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  let navigate = useNavigate();
  const iniciarSesion = async () => {
    await axios
      .post(url, user)

      .then((response) => {
        let token = response.data;

        // cookies.set("id", resultado.id, { path: "/" });
        cookies.set("userName", user.userName, { path: "/" });
        if (token !== null) {
          sessionStorage.setItem("token", token);
          toast(`Bienvenido ${user.userName}`);
          navigate(`/menu`);
        } else {
          alert("El usuario o la contraseña son incorrectos");
        }
      })
      // .then((response) => {
      //   if (response.length > 0) {
      //     var respuesta = response[0];

      //     cookies.set("id", respuesta.id, { path: "/" });
      //     cookies.set("userName", respuesta.userName, { path: "/" });
      //     // const notificacion = ()=>toast(`Bienvenido ${respuesta.userName}`)

      //     toast(`Bienvenido ${respuesta.userName}`);
      //     // <nav>
      //     //   <Link to="/menu"></Link>
      //     // </nav>
      //     navigate(`/menu`);
      //     //   this.props.History.push("/menu");
      //     // document.location.href="/menu";
      //   } else {
      //     alert("El usuario o la contraseña son incorrectos");
      //   }
      // })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="containerPrincipal">
      <div className="containerLogin">
        <div className="form-group">
          <label>Usuario: </label>
          <br />
          <input
            type="text"
            className="form-control"
            name="userName"
            onChange={handleChange}
           
          />
        
          <br></br>
          <label>Contraseña: </label>
          <br />
          <input
            type="password"
            className="form-control"
            name="password"
            onChange={handleChange}
            
          />
          <br />
          <button
            className="btn btn-primary"
            onClick={() => {
              iniciarSesion();
            }}
          >
            Iniciar Sesion
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
