import "../css/menu.css";
import { React } from "react";
import { Link, useMatch, useResolvedPath, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

let cookie = new Cookies();
function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link className={match ? "active" : ""} to={to} {...props}>
      {children}
    </Link>
  );
}

function Menu({ children, to, ...props }) {
  let navigate = useNavigate();
  const CerrarSesion = () => {
    sessionStorage.removeItem("token");
    navigate(`/`);
  };
  return (
    <>
      <div>
        {/* <p>{JSON.stringify(userData)}</p> */}
        <div>
          <ToastContainer />
        </div>

        <div>
          <header className="main-header">
            <div className="container">
              <nav className="navbar navbar-expand-lg main-nav px-0">
                {/* <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#mainMenu"
                  aria-controls="mainMenu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar icon-bar-1"></span>
                  <span className="icon-bar icon-bar-2"></span>
                  <span className="icon-bar icon-bar-3"></span>
                </button> */}
                <div className="collapse navbar-collapse" id="mainMenu">
                  <ul className="navbar-nav ml-auto text-uppercase f1">
                    <li>
                      <CustomLink to="/menu">home</CustomLink>
                    </li>
                    <li>
                      <CustomLink to="/postulantes">Postulantes</CustomLink>
                    </li>
                    <li>
                      <div className="Test">
                        {/* <h6>{cookie.get("userName")}</h6> */}

                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            CerrarSesion();
                          }}
                        >
                          Cerrar Sesion
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div id="pageBody">
              <div className="container">{children}</div>
            </div>
          </header>

          <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
            integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
            crossOrigin="anonymous"
          ></script>
          <script
            src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
            integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
            crossOrigin="anonymous"
          ></script>

          <script src="scripts/index.js"></script>
        </div>
      </div>
      <p></p>
    </>
  );
}

export default Menu;
