import "../css/menu.css";
import { React, useEffect } from "react";
import { useData } from "../hooks/useData";
import { useDataFalse } from "../hooks/useDataFalse";
import "react-toastify/dist/ReactToastify.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Home() {
  const { userData, getData } = useData();
  const { userDataFalse, getDataFalse } = useDataFalse();

  useEffect(() => {
    getDataFalse();
    getData();
  }, []);

  const data = [
    {
      name: "Contactar",
      Si: userData,
      No: userDataFalse,
    },
    {
      name: "Entrevistar",
      Si: userData,
      No: userDataFalse,
    },
    {
      name: "Contestó",
      Si: userData,
      No: userDataFalse,
    },
  ];
  return (
    <div className="container">
      <div className="card text-center">
        <div className="card text-center">
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-6">
                <h4
                  className="card-title d-flex justify-content-center"
                  style={{ textAlign: "center" }}
                >
                  Postulantes
                </h4>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="No" stackId="a" fill="#8884d8" />
                    <Bar dataKey="Si" stackId="a" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <p className="card-text"></p>
          </div>
        </div>
      </div>
    </div>
  );
}
